import React from 'react';
import Image from 'next/image';
import { t, Trans } from '@lingui/macro';
import { TimelineMax, TweenLite } from 'gsap';

import { IconPause, IconPlayAlt } from 'components/assets';

import articlePlaceholder from '/public/static/images/article-placeholder.jpg';
import articlePlaceholderAdalvo from '/public/static/images/article-placeholder-adalvo.png';

import s from './Ticker.module.scss';
import { config } from 'utils/config';

type Props = {
  title?: string;
  delay?: number;
  timer?: number;
  hideTitle?: boolean;
  children: React.ReactNode;
};

export const Ticker = ({ title, children, timer = 5, hideTitle = false }: Props) => {
  const [paused, setPaused] = React.useState(false);
  const [locked, setLocked] = React.useState(false);
  const barRef = React.useRef<HTMLDivElement | null>(null);
  const imageRef = React.useRef<HTMLUListElement | null>(null);
  const tickRef = React.useRef<HTMLUListElement | null>(null);
  const listRef = React.useRef<HTMLUListElement | null>(null);

  const childrenCount = React.Children.count(children);
  const transitionSpeed = 1;

  let goTo;

  const onClick = (e) => {
    const index = Array.from(e.target.parentElement.children).indexOf(e.target);

    if (goTo) {
      // goTo(index);
    }
  };

  React.useEffect(() => {
    if (!listRef.current || !imageRef.current || !tickRef.current) {
      return;
    }

    const imageEls = Array.from(imageRef.current.children);
    const textEls = Array.from(listRef.current.children);

    if (imageEls.length === 0) {
      return;
    }

    const showItem = (index: number, instant?: boolean) => {
      const imageEl = imageEls[index];
      const textEl = textEls[index];
      if (!imageEl || !textEl) return;

      textEl.getElementsByTagName('a')[0].setAttribute('tabIndex', '0');

      // Image
      const t = new TimelineMax();
      t.set(imageEl, { zIndex: 2 });
      t.set(textEl, { pointerEvents: 'all' });
      t.fromTo(
        imageEl,
        transitionSpeed,
        { y: '-100%' },
        { y: '0%', ease: 'Power4.easeInOut' }
        // transitionSpeed / 2
      );

      // Text
      TweenLite.fromTo(
        textEl,
        transitionSpeed,
        { x: -20, opacity: 0 },
        { x: 0, opacity: 1, ease: 'Power4.easeOut' }
      ).delay(transitionSpeed / 2);
    };

    // Don't tick over if single article
    if (childrenCount === 1) {
      showItem(0, true);
      return;
    }

    const hideItem = (index: number, instant?: boolean) => {
      const imageEl = imageEls[index];
      const textEl = textEls[index];
      if (!imageEl || !textEl) return;

      textEl.getElementsByTagName('a')[0].setAttribute('tabIndex', '-1');

      // Image
      const t = new TimelineMax();
      t.set(imageEl, { zIndex: 1 });
      t.fromTo(
        imageEl,
        transitionSpeed,
        { y: '0%' },
        { y: '50%', ease: 'Power4.easeInOut' }
        // transitionSpeed / 2
      );
      t.set(imageEl, { zIndex: 0 });

      // Text
      TweenLite.fromTo(
        textEl,
        transitionSpeed / 2,
        { x: 0, opacity: 1 },
        { x: 20, opacity: 0, ease: 'Power4.easeIn' }
      );
    };

    const updateProgress = (index: number) => {
      const fromPercentage = 1 - index / childrenCount;
      const percentage = 1 - (index + 1) / childrenCount;
      const ease = 'Linear.easeNone';

      if (index === 0) {
        TweenLite.fromTo(
          barRef.current,
          timer,
          { x: '-100%' },
          {
            x: `-${percentage * 100}%`,
            ease,
            onComplete: () => transition(),
          }
        );
      } else {
        TweenLite.fromTo(
          barRef.current,
          timer,
          {
            x: `-${fromPercentage * 100}%`,
          },
          {
            x: `-${percentage * 100}%`,
            ease,
            onComplete: () => transition(),
          }
        );
      }
    };

    let current = 0;

    goTo = (index: number) => {
      // console.log('goTo', index);

      TweenLite.killTweensOf(barRef.current, imageEls, textEls);

      current = index - 1;
      transition();
    };

    const transition = () => {
      let nextIndex = current + 1;

      if (nextIndex > childrenCount - 1) {
        nextIndex = 0;
      }

      updateProgress(nextIndex);
      showItem(nextIndex);
      hideItem(current);

      current = nextIndex;
    };

    const init = () => {
      updateProgress(current);
      showItem(current);
    };

    init();

    return () => {
      TweenLite.killTweensOf(barRef.current);
    };
  }, []);

  React.useEffect(() => {
    if (!listRef.current || !barRef.current[0]) return;

    if (paused) {
      TweenLite.getTweensOf(barRef.current)[0].pause();
    } else {
      TweenLite.getTweensOf(barRef.current)[0].resume();
    }
  }, [paused]);

  React.useEffect(() => {
    if (locked) {
      setPaused(true);
    } else {
      setPaused(false);
    }
  }, [locked]);

  const mouseEnter = () => {
    if (locked) return;
    setPaused(true);
  };

  const mouseLeave = () => {
    if (locked) return;
    setPaused(false);
  };

  return (
    <section
      className={s.ticker}
      aria-roledescription="carousel"
      aria-live="polite"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {!hideTitle && <h2 className={s.ticker__title}>{title ?? <Trans>Latest news</Trans>}</h2>}
      <button
        className={s.ticker__pause}
        onClick={() => setLocked(!locked)}
        aria-label={
          paused ? 'Resume automatic news ticker slides' : 'Pause automatic news ticker slides'
        }
      >
        {paused ? <IconPlayAlt /> : <IconPause />}
      </button>

      <ul className={s.ticker__imageList} ref={imageRef} aria-hidden="true">
        {React.Children.map(children, (c: JSX.Element, i) => (
          <li className={s.ticker__imageItem} key={i}>
            {c.props.image ? (
              <Image
                src={c.props.image.ticker?.url ?? c.props.image.url}
                layout="fill"
                objectFit="cover"
                alt=""
                role="presentation"
              />
            ) : (
              <PlaceholderImage />
            )}
          </li>
        ))}
      </ul>
      <ul className={s.ticker__list} ref={listRef}>
        {React.Children.map(children, (component: JSX.Element, i) => (
          <li
            key={i}
            className={s.ticker__item}
            aria-roledescription="slide"
            aria-label={`${i} of ${childrenCount}`}
          >
            {React.cloneElement(component, { hideTitle })}
          </li>
        ))}
      </ul>
      <div className={s.ticker__timer}>
        <ul className={s.ticker__tickList} ref={tickRef}>
          {React.Children.map(children, (_component: JSX.Element, i) => (
            <li key={i} className={s.ticker__tick} onClick={onClick} />
          ))}
        </ul>
        <div className={s.ticker__barWrap}>
          <div className={s.ticker__bar} ref={barRef} />
        </div>
      </div>
    </section>
  );
};

const PlaceholderImage = () => {
  if (config.theme === 'adalvo') {
    return <img src={articlePlaceholderAdalvo.src} />;
  }
  return <img src={articlePlaceholder.src} />;
};
